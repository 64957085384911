<template>
  <div>
    <el-table :data="oinvForm.oinv_part_list" border @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary>
      <el-table-column type="selection" width="48" align="center" />
      <el-table-column label="序号" width="60" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column key="procurement_type" label="是否为kong" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'oinv_part_list.' + scope.$index + '.procurement_type'" label-width="150">
            <el-select v-model="oinvForm.oinv_part_list[scope.$index].procurement_type" clearable filterable placeholder="请选择" disabled>
              <el-option
                v-for="item in [
                  { value: 1, label: '是' },
                  { value: 0, label: '否' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="我司货号" width="150" key="prod_no">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.prod_no'">
            <el-input v-model="oinvForm.oinv_part_list[scope.$index].prod_no" disabled maxlength="30" show-word-limit placeholder="暂无我司货号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="invoice_name_type" label="开票类目" width="150">
        <template v-slot="scope">
          <el-form-item :prop="'oinv_part_list.' + scope.$index + '.invoice_name_type'" label-width="150">
            <el-select v-model="oinvForm.oinv_part_list[scope.$index].invoice_name_type" clearable filterable placeholder="请选择" disabled>
              <el-option
                v-for="item in [
                  { value: '宠物用品', label: '宠物用品' },
                  { value: '玩具', label: '玩具' },
                  { value: '塑料制品', label: '塑料制品' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column :label="oinvForm.procurement_type === 0 ? '加工开票品名' : '开票名称'" width="150" key="prod_bhsname">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.prod_bhsname'">
            <el-input
              v-model="oinvForm.oinv_part_list[scope.$index].prod_bhsname"
              maxlength="100"
              disabled
              show-word-limit
              placeholder="暂无加工开票品名"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column key="prod_cust_no" label="客户货号" width="150">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.prod_cust_no'">
            <el-input
              v-model="oinvForm.oinv_part_list[scope.$index].prod_cust_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无我司货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量" width="150" key="oinv_part_num">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.oinv_part_num'" :rules="{ required: true, message: ' ' }">
            <el-input
              disabled
              v-model="oinvForm.oinv_part_list[scope.$index].oinv_part_num"
              @input="oinvForm.oinv_part_list[scope.$index].oinv_part_num = helper.keepTNum2(oinvForm.oinv_part_list[scope.$index].oinv_part_num)"
              @blur="oinvForm.oinv_part_list[scope.$index].oinv_part_num = helper.calcPrice(oinvForm.oinv_part_list[scope.$index].oinv_part_num, 4, 10000)"
              @change="calcSubTotal(scope.$index)"
              maxlength="9"
              show-word-limit
              placeholder="暂无数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="美金单价" width="150" v-if="oinvForm.procurement_type === 1" key="usd_price">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.usd_price'">
            <el-input v-model="oinvForm.oinv_part_list[scope.$index].usd_price" disabled maxlength="30" show-word-limit placeholder="暂无美金单价"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量单位" width="150" key="prod_unit">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.prod_unit'">
            <el-select v-model="oinvForm.oinv_part_list[scope.$index].prod_unit" filterable placeholder="请选择数量单位" disabled>
              <el-option v-for="item in unitsList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="美金金额" width="150" v-if="oinvForm.procurement_type === 1" key="usd_money">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.usd_money'">
            <el-input v-model="oinvForm.oinv_part_list[scope.$index].usd_money" disabled maxlength="30" show-word-limit placeholder="暂无美金金额"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="进口商品采购价￥" width="150" v-if="oinvForm.procurement_type === 1" key="tariff_scon_price">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.tariff_scon_price'">
            <el-input v-model="oinvForm.oinv_part_list[scope.$index].tariff_scon_price" disabled maxlength="30" show-word-limit placeholder="暂无"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="关税" width="150" v-if="oinvForm.procurement_type === 1" key="import_tariff">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.import_tariff'">
            <el-input
              v-model="oinvForm.oinv_part_list[scope.$index].import_tariff"
              @input="val => (oinvForm.oinv_part_list[scope.$index].import_tariff = keep2Decimal(val))"
              @change="linkageComputing(scope.$index)"
              maxlength="15"
              show-word-limit
              placeholder="暂无关税"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="关税$" width="150" v-if="oinvForm.procurement_type === 1" key="import_tariff_usd">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.import_tariff_usd'">
            <el-input
              v-model="oinvForm.oinv_part_list[scope.$index].import_tariff_usd"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无关税$"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="进口商品采购单价￥" width="150" key="prod_price">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.prod_price'">
            <el-input
              v-model="oinvForm.oinv_part_list[scope.$index].prod_price"
              @input="val => (oinvForm.oinv_part_list[scope.$index].prod_price = keep5Decimal(val))"
              @change="calcSubTotal(scope.$index)"
              maxlength="11"
              show-word-limit
              disabled
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="进口商品采购成本￥" width="150" key="oinv_part_subtotal">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.oinv_part_subtotal'">
            <el-input v-model="oinvForm.oinv_part_list[scope.$index].oinv_part_subtotal" disabled maxlength="30" show-word-limit placeholder="暂无"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="进口商品采购成本$" width="150" v-if="oinvForm.procurement_type === 1" key="import_procurement_cost">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.import_procurement_cost'">
            <el-input
              v-model="oinvForm.oinv_part_list[scope.$index].import_procurement_cost"
              disabled
              maxlength="15"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="进口商品采购单价$" width="150" v-if="oinvForm.procurement_type === 1" key="import_procurement_price">
        <template v-slot="scope">
          <el-form-item label-width="150" :prop="'oinv_part_list.' + scope.$index + '.import_procurement_price'">
            <el-input
              v-model="oinvForm.oinv_part_list[scope.$index].import_procurement_price"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { keep2Decimal, keep5Decimal } from '@assets/js/regExUtil';
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { objectArrayReduce } from '@assets/js/arrayUtils';

export default {
  name: 'OinvShaerPartList',
  props: {
    oinvForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      unitsList: [],
      multiSelection: [],
      custOptionList: [],
      oinvPartTypeList: [
        { id: 1, label: '内贸采购' },
        { id: 2, label: '委外加工' }
      ]
    };
  },
  components: {
    // textEnlargement
  },
  created() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    keep5Decimal,
    initData() {
      this.getCustDport2();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
      this.$emit('handleSelectionChange', this.multiSelection);
    },
    //联动计算
    linkageComputing(index, isCompute = true) {
      //字段防护
      if (!new BigNumber(Number(this.oinvForm.oinv_part_list[index].import_tariff)).isFinite()) {
        this.oinvForm.oinv_part_list[index].import_tariff = 0;
      }
      //计算
      this.oinvForm.oinv_part_list[index].tariff_scon_price = new BigNumber(
        Number(this.oinvForm.oinv_part_list[index].usd_money) * Number(this.oinvForm.tariff_rate)
      ).toFixed(2); //计算进口商品采购价
      this.oinvForm.oinv_part_list[index].import_tariff_usd = new BigNumber(
        Number(this.oinvForm.oinv_part_list[index].import_tariff) / Number(this.oinvForm.tariff_rate)
      ).toFixed(2); //计算关税$
      this.oinvForm.oinv_part_list[index].oinv_part_subtotal = new BigNumber(
        Number(this.oinvForm.oinv_part_list[index].tariff_scon_price) + Number(this.oinvForm.oinv_part_list[index].import_tariff)
      ).toFixed(2); //计算进口商品采购成本￥
      this.oinvForm.oinv_part_list[index].prod_price = new BigNumber(
        Number(this.oinvForm.oinv_part_list[index].oinv_part_subtotal) / Number(this.oinvForm.oinv_part_list[index].oinv_part_num)
      ).toFixed(4); //计算进口商品采购单价￥
      this.oinvForm.oinv_part_list[index].import_procurement_cost = new BigNumber(
        Number(this.oinvForm.oinv_part_list[index].usd_money) + Number(this.oinvForm.oinv_part_list[index].import_tariff_usd)
      ).toFixed(2); //计算进口商品采购成本$
      this.oinvForm.oinv_part_list[index].import_procurement_price = new BigNumber(
        Number(this.oinvForm.oinv_part_list[index].import_procurement_cost) / Number(this.oinvForm.oinv_part_list[index].oinv_part_num)
      ).toFixed(4); //计算进口商品采购单价$
      //计算最后一位关税 = 除最后一位关税之和 - 主表关税金额
      if (this.oinvForm.oinv_part_list.length > 1 && isCompute) {
        let import_tariff_all = objectArrayReduce(
          this.oinvForm.oinv_part_list.filter((element, index) => index !== this.oinvForm.oinv_part_list.length - 1),
          'import_tariff'
        );
        this.oinvForm.oinv_part_list[this.oinvForm.oinv_part_list.length - 1].import_tariff = new BigNumber(
          this.oinvForm.tariff_price - import_tariff_all
        ).toFixed(2, 0);
        //判断子表关税总额不得大于主表关税金额
        if (this.oinvForm.oinv_part_list[this.oinvForm.oinv_part_list.length - 1].import_tariff < 0) {
          this.oinvForm.oinv_part_list[index].import_tariff = 0;
          this.oinvForm.oinv_part_list[this.oinvForm.oinv_part_list.length - 1].import_tariff = 0;
          //只递归计算一次
          this.linkageComputing(index, false);
          //只递归计算一次
          this.linkageComputing(this.oinvForm.oinv_part_list.length - 1, false);
          return this.$message.error('关税总金额不可大于主表关税金额！');
        }
        //只递归计算一次
        this.linkageComputing(this.oinvForm.oinv_part_list.length - 1, false);
      }
    },
    // 获取数量单位
    getCustDport2() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.unitsList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = [];
      for (let i = 0; i < 41; i++) {
        let temp = '';
        if (i === 0) {
          temp = '合计';
        } else {
          temp = '';
        }
        totalList.push(temp);
      }
      columns.forEach((column, index) => {
        let datas = data;
        let totalA = new BigNumber(0);
        let totalB = new BigNumber(0);
        let totalC = new BigNumber(0);
        //非进口
        let totalD = new BigNumber(0);
        let totalE = new BigNumber(0);
        let totalF = new BigNumber(0);
        let totalG = new BigNumber(0);
        let totalH = new BigNumber(0);
        let totalI = new BigNumber(0);
        for (let i in datas) {
          totalA = totalA.plus(datas[i].oinv_part_num ? datas[i].oinv_part_num : 0); //数量
          totalB = totalB.plus(datas[i].oinv_part_subtotal ? datas[i].oinv_part_subtotal : 0); //金额 & 进口商品采购成本￥
          totalC = totalC.plus(datas[i].oinv_actu_tax ? datas[i].oinv_actu_tax : 0); //实际税额
          totalD = totalD.plus(datas[i].usd_money ? datas[i].usd_money : 0); //美金金额
          totalE = totalE.plus(datas[i].tariff_scon_price ? datas[i].tariff_scon_price : 0); //进口商品采购价￥rmb
          totalF = totalF.plus(datas[i].import_tariff ? datas[i].import_tariff : 0); //关税rmb
          totalG = totalG.plus(datas[i].import_tariff_usd ? datas[i].import_tariff_usd : 0); //关税usd
          totalH = totalH.plus(datas[i].import_procurement_cost ? datas[i].import_procurement_cost : 0); //进口商品采购成本$
          totalI = totalI.plus(datas[i].prod_price ? datas[i].prod_price : 0); //单价 & 进口商品采购单价￥
        }
        totalA = totalA.toNumber();
        totalB = totalB.toNumber();
        totalC = totalC.toNumber();
        if (this.oinvForm.procurement_type === 0) {
          totalList[6] = totalA.toFixed(2);
          totalList[9] = totalC.toFixed(2);
          totalList[11] = totalB.toFixed(2);
        } else {
          totalList[7] = totalA.toFixed(2); //数量
          totalList[10] = totalD.toFixed(2); //美金金额
          totalList[11] = totalE.toFixed(2); ////进口商品采购价￥rmb
          totalList[12] = totalF.toFixed(2); //关税rmb
          totalList[13] = totalG.toFixed(2); //关税usd
          totalList[15] = totalB.toFixed(2); //金额 & 进口商品采购成本￥
          totalList[16] = totalH.toFixed(2); //进口商品采购成本$
        }
      });
      return totalList;
    },
    // 计算金额
    calcSubTotal(val) {
      let totalA = new BigNumber(1);
      totalA = totalA
        .times(this.oinvForm.oinv_part_list[val].oinv_part_num ? this.oinvForm.oinv_part_list[val].oinv_part_num : 0)
        .times(this.oinvForm.oinv_part_list[val].prod_price ? this.oinvForm.oinv_part_list[val].prod_price : 0);
      totalA = totalA.toNumber();
      this.oinvForm.oinv_part_list[val].oinv_part_subtotal = this.helper.haveFour(totalA);
      this.calcTax(val);
    },
    // 计算实际税额
    calcTax(val) {
      let totalA = new BigNumber(1);
      totalA = totalA
        .times(this.oinvForm.oinv_part_list[val].oinv_part_subtotal ? this.oinvForm.oinv_part_list[val].oinv_part_subtotal : 0)
        .div(1 + Number(this.oinvForm.oinv_part_list[val].prod_hsinrate) / 100)
        .times(Number(this.oinvForm.oinv_part_list[val].prod_hsinrate ? this.oinvForm.oinv_part_list[val].prod_hsinrate : 0) / 100)
        .toFixed(2);
      this.oinvForm.oinv_part_list[val].oinv_actu_tax = totalA;
      this.$emit('calcMainChange');
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.oinvForm.oinv_part_list[val1].prod_desc;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.oinvForm.oinv_part_list[this.textEnlargementNum].prod_desc = val;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
